import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Layout } from "../components/common"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"
import ContactUsBtn from "../components/common/ContactUsBtn";

let AgricultureImage = require('../images/page-images/agriculture-drone-field.jpg')

class Agriculture extends Component {
    render() {
        return (
            <Layout>

                <div className="container">
                    <h1>
                        Precision Agriculture & Turf
                    </h1>
                    <div className="row">
                        <div className="col-md-6">
                            <img className={'mbl'} src={AgricultureImage} alt=""/>
                        </div>
                        <div className="col-md-6">
                            <p>
                                We provide complete Drone Precision Agriculture services including RGB, Thermal IR, and NDVI imaging.  Inefficiency and inaccuracy in monitoring large crop fields are significant obstacles in the farming industry.  Our services provide benefits in both of those areas.  Problems with crop health can be identified and therefore resolved more quickly to prevent or minimize damage and maximize yield.


                                <ul>
                                    <li>Quick topographic land contour surveys provide data for irrigation design</li>
                                    <li>Identify specific locations of plant health issues early such as fungal and pest infestations</li>
                                    <li>Spot issues with irrigation coverage and locations of leaks</li>
                                    <li>Improve crop load estimates to make better fruit thinning and other management decisions</li>
                                </ul>

                            </p>
                            <ContactUsBtn/>
                        </div>
                    </div>

                </div>
            </Layout>
        )
    }
}

Agriculture.propTypes = {}

export default Agriculture
